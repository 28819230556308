<script setup lang="ts">
import type { mastodon } from 'masto'

const props = defineProps({
  "card": null,
  "smallPictureOnly": { type: Boolean,  },
  "root": { type: Boolean,  }
})

const providerName = props.card.providerName

const gitHubCards = (usePreferences('experimentalGitHubCards'))
</script>

<template>
  <LazyStatusPreviewGitHub v-if="gitHubCards && providerName === 'GitHub'" :card="card" />
  <LazyStatusPreviewStackBlitz v-else-if="gitHubCards && providerName === 'StackBlitz'" :card="card" :small-picture-only="smallPictureOnly" :root="root" />
  <StatusPreviewCardNormal v-else :card="card" :small-picture-only="smallPictureOnly" :root="root" />
</template>
